import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import './PredictionOutput.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FiArrowUp, FiMinus, FiArrowDown } from 'react-icons/fi';
import axios from 'axios';

const PredictionOutput = ({ gptOutput, featureImportance, positiveFeatures, negativeFeatures, ticker }) => {
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newsText, setNewsText] = useState(''); // State to hold extracted news text
  const [firstPart, setFirstPart] = useState('');
  const [remainingPart, setRemainingPart] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' 
          ? 'http://127.0.0.1:5000' 
          : 'https://flask-backend-52245432644.us-central1.run.app';

        const response = await axios.get(`${baseURL}/actuals-data`, {
          params: { ticker },
        });

        const result = response.data.data;
        if (result && result.length > 0) {
          const formattedData = result.map(row => ({
            Date: row.date,
            Prediction_Value: Number(row.prediction_value),
            Close: Number(row.close)
          }));
          setGraphData(formattedData);
        } else {
          setError("No model fit information available yet");
        }
      } catch (err) {
        console.error('Error fetching data from API:', err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ticker]);

  const formatValue = (value) => {
    return (typeof value === 'number' && !isNaN(value)) ? `$${value.toFixed(2)}` : 'N/A';
  };

  // Function to split gptOutput and extract "News" content
  const splitGptOutput = (gptOutput) => {
    // Sanitize and parse the HTML content
    const sanitizedOutput = DOMPurify.sanitize(gptOutput);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedOutput, 'text/html');
  
    // Locate paragraphs and filter based on the presence of the "News" keyword
    const paragraphs = Array.from(doc.querySelectorAll('p'));
    let newsStartIndex = -1;
    let newsEndIndex = -1;
  
    paragraphs.forEach((p, index) => {
      if (p.textContent.trim().toLowerCase().includes("news:")) {
        newsStartIndex = index;
      }
      // Check if "Insider trading" or similar marker indicates the end of the news section
      if (newsStartIndex !== -1 && p.textContent.trim().toLowerCase().includes("insider trading")) {
        newsEndIndex = index;
      }
    });
  
    if (newsStartIndex !== -1) {
      const newsContent = paragraphs.slice(newsStartIndex, newsEndIndex !== -1 ? newsEndIndex : undefined)
        .map(p => p.textContent.trim().replace(/^news:\s*/i, ''))
        .join(' ');
  
      setNewsText(newsContent);
    } else {
      setNewsText("No specific news section found.");
    }
  
    // Extract text before the "News" section
    const firstPart = paragraphs.slice(0, newsStartIndex).map(p => p.outerHTML).join("");
    setFirstPart(firstPart);
  
    // Extract text after the "News" section
    const remainingPart = newsEndIndex !== -1 ? paragraphs.slice(newsEndIndex).map(p => p.outerHTML).join("") : '';
    setRemainingPart(remainingPart);
  };
  

  useEffect(() => {
    splitGptOutput(gptOutput); // Extract news text and split gptOutput
  }, [gptOutput]);

  return (
    <div className="prediction-output-container">
    {(!loading && !error && graphData.length > 0) ? (
      <div className="graph-container">
        <h3>Last 30 days: Predicted vs Actual</h3>
        <ResponsiveContainer>
          <LineChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="Date"
              textAnchor="end"
              tickFormatter={(date) => {
                const dateObj = new Date(date);
                if (isNaN(dateObj.getTime())) return '';
                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                const day = String(dateObj.getDate()).padStart(2, '0');
                return `${month}/${day}`;
              }}
            />
            <YAxis domain={['auto', 'auto']} tickFormatter={(value) => `$${Math.round(value)}`} />
            <Tooltip formatter={(value, name) => [formatValue(value), name]} />
            <Legend />
            <Line type="monotone" dataKey="Prediction_Value" stroke="#1D3557" name="Prediction" strokeWidth={3} dot={false} strokeDasharray="5 5" />
            <Line type="monotone" dataKey="Close" stroke="#139C8D" name="Actual" strokeWidth={3} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    ) : null}

    {(!loading && (error || graphData.length === 0)) && (
      
      <div className="no-graph-message">
        <div className="error-message">{error || `No data available for the ticker ${ticker}.`}</div>
      </div>
    )}
      <div className="prediction-text-container">
        <div
          className="gpt-output-content"
          dangerouslySetInnerHTML={{ __html: firstPart }}
        />

        <div className="influences-container">
          <div className="influence-box influence-positive">
            <div className="icon">+</div>
            <div className="influence-text">
              <h3>Positive Influences</h3>
              <ul>
                {positiveFeatures.map((feature, index) => (
                  <li key={index}>
                    {feature}: +{featureImportance.find(f => f.Feature === feature)?.Importance?.toFixed(3) || 'N/A'}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="influence-box influence-negative">
            <div className="icon">-</div>
            <div className="influence-text">
              <h3>Negative Influences</h3>
              <ul>
                {negativeFeatures.map((feature, index) => (
                  <li key={index}>
                    {feature}: -{featureImportance.find(f => f.Feature === feature)?.Importance?.toFixed(3) || 'N/A'}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* News Section as Table */}
        <div className="news-section">
    <h3>Trending News</h3>
    <table className="news-table">
    <tbody>
        <tr>
          <td className="news-icon-cell"><FiArrowUp /></td>
          <td>{newsText}</td>
        </tr>
          <tr>
            <td className="news-icon-cell"><FiMinus /></td>
            <td></td>
          </tr>
          <tr>
            <td className="news-icon-cell"><FiArrowDown /></td>
            <td></td>
          </tr>
      
    </tbody>
  </table>
</div>


        <div
          className="gpt-output-content"
          dangerouslySetInnerHTML={{ __html: remainingPart }}
        />
      </div>
    </div>
  );
};

export default PredictionOutput;
