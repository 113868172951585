import React, { useState, useEffect } from 'react';
import { useRef } from 'react'; 
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ProgressPage.css';

const ProgressPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTicker = params.get('t');
  const navigate = useNavigate();
  const { ticker } = location.state || {}; // Extract the ticker value from state
  const [progress, setProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [fetchingError, setFetchingError] = useState(false);
  const [predictionData, setPredictionData] = useState(null);
  const [anonUserCreated, setAnonUserCreated] = useState(false);
  const [anonUserId, setAnonUserId] = useState(null); // Store the anon user ID
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track if the user is logged in
  const requestMade = useRef(false); // Use useRef to track if request was made

  useEffect(() => {
    const initialize = async () => {
      try {
        let userId = localStorage.getItem('uid') || localStorage.getItem('anonUserId');
        
        if (!userId && !anonUserCreated) {
          // If no user ID exists in localStorage, create an anonymous user
          await createAnonymousUser(); // Wait until anonUserId is created
          userId = localStorage.getItem('anonUserId');
          
          if (!userId) {
            throw new Error('Failed to retrieve anonUserId after creating it');
          }
          
          setAnonUserId(userId); // Set the newly created anon user ID
        } else {
          setAnonUserId(userId); // Set user ID from localStorage (either anon or authorized)
          setIsLoggedIn(!!localStorage.getItem('uid')); // Check if user is logged in
        }

        // Only fetch prediction data if there's a ticker and no request has been made yet
         // Handle deeplink access with `pid`

         if (ticker && !requestMade.current) {
          simulateProgress();
          fetchPredictionData(ticker, userId); // Pass the userId (anon or authorized) to the prediction request
        } 
        else if (urlTicker && !requestMade.current) {
          simulateProgress();
          fetchPredictionData(urlTicker, userId);
        } 
        else {
          alert('No ticker or prediction ID found');
          console.log('No ticker or prediction ID found');
          navigate('/'); // Redirect if neither is present
        }

       
      } catch (error) {
        console.error('Initialization error:', error);
        setFetchingError(true);
      }
    };
    initialize(); // Call the initialize function inside useEffect
  }, [urlTicker, ticker, navigate]);

  // Create anonymous user function
  const createAnonymousUser = async () => {
    try {
      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';

          // Fetch client IP address from an external service
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const clientIp = ipResponse.data.ip;
    console.log(clientIp);
   
    const response = await axios.post(`${baseURL}/create-anon-user`, {}, {
      headers: {
        'Client-IP': clientIp, // Add IP address to headers
      }
    });

      const { user_id } = response.data;
      localStorage.setItem('anonUserId', user_id);
      setAnonUserId(user_id);  // Save anon user ID to state
      setAnonUserCreated(true);  // Ensure the request is only made once
    } catch (error) {
      console.error('Error creating anonymous user:', error);
      throw new Error('Anonymous user creation failed');
    }
  };

  // Progress bar simulation
  const simulateProgress = () => {
    let interval;
    let currentPhase = 1;
    interval = setInterval(() => {
      setProgress((prevProgress) => {
        let nextProgress;
        if (currentPhase === 1) {
          nextProgress = prevProgress + Math.floor(Math.random() * 5 + 10); // Fetched data (10-30%)
          setStatusMessage('1/4: Gathering Data from Sources');
          if (nextProgress >= 30) currentPhase = 2;
        } else if (currentPhase === 2) {
          nextProgress = prevProgress + Math.floor(Math.random() * 5 + 10); // Preprocessing (30-50%)
          setStatusMessage('2/4: Cleaning up the Data');
          if (nextProgress >= 50) currentPhase = 3;
        } else if (currentPhase === 3) {
          nextProgress = prevProgress + Math.floor(Math.random() * 5 + 10); // Build the model (50-70%)
          setStatusMessage('3/4: Training the Model');
          if (nextProgress >= 70) currentPhase = 4;
        } else if (currentPhase === 4) {
          nextProgress = prevProgress + Math.floor(Math.random() * 5 + 10); // Validating (70-93%)
          setStatusMessage('4/4: Evaluating the Output Signal');
          if (nextProgress >= 95) {
            setStatusMessage('May take up to a minute...');
            clearInterval(interval); // Stop at 95% and wait for the API response

            return 95;
          }
        }
        return nextProgress;
      });
    }, 1800); // Update progress every 1 second
  };

  // Fetch prediction data
  const fetchPredictionData = async (ticker, userId) => {
     // Prevent multiple requests using the ref
    if (requestMade.current) return;
    requestMade.current = true; // Set the ref to true when the request is made

    setRequestInProgress(true);
    try {
      console.log("Anonymous user ID: " + userId);
      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';

       const response = await fetch(`${baseURL}/get-prediction?ticker=${ticker}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userId}`, // Send anon user ID in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }

      const result = await response.json();
      const {prediction_id, prediction }= result;
      console.log("prediction ID:" , prediction_id);
      localStorage.setItem('predictionId', prediction_id);

      // Get the current date and time when the prediction is fetched
      const currentDate = new Date();
      const options = { 
        month: 'short', 
        day: 'numeric', 
        year: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true, 
        timeZone: 'America/New_York' // Use Eastern Time Zone
      };
      
      const dayBefore = new Date(currentDate);
      dayBefore.setDate(currentDate.getDate() - 1);
      const dayBeforeAnon = dayBefore.toLocaleDateString('en-US', options) + ' ET';

      const weekBefore = new Date(currentDate);
      weekBefore.setDate(currentDate.getDate() - 7);
      const weekBeforeAnon = weekBefore.toLocaleDateString('en-US', options) + ' ET';

      // Store these values in localStorage
      localStorage.setItem('dayBeforeAnon', dayBeforeAnon);
      localStorage.setItem('weekBeforeAnon', weekBeforeAnon);

      setPredictionData(result); // Save the fetched prediction data
      setProgress(100); // Progress to 100% when the data is fetched
      navigate('/dashboard', { state: { prediction: result } }); // Navigate to dashboard with prediction data
    } catch (error) {
      console.error('Error fetching prediction:', error);
      setFetchingError(true); // Set error flag
    } finally {
      setRequestInProgress(false);
      requestMade.current = false; // Reset the ref after the request is completed
    }
  };

  return (
    <div className="progress-page">
      <h1>MarketCrunch AI&#8482; <br/><br/>Our AI-Engine is analyzing {ticker ? ticker : urlTicker}.</h1>
      <h2>{statusMessage}</h2>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
      <p>{progress}% Completed!</p>

      {fetchingError && (
        <div className="error-message">
          <p>We encountered an issue. Retrying...</p>
        </div>
      )}

      {isLoggedIn ? (
        <p>You are logged in.</p>
      ) : (
        <p>Building your AI model</p>
      )}
    </div>
  );
};

export default ProgressPage;