import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UpgradePopup.css';

const UpgradePopup = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();
  const [isYearly, setIsYearly] = useState(true); // State for billing cycle toggle
  const [selectedPlan, setSelectedPlan] = useState(1); // State for selected plan

  if (!isOpen) {
    return null; // Do not render if the popup is closed
  }

  // Toggle between monthly and yearly pricing
  const handleBillingToggle = () => {
    setIsYearly((prev) => !prev);
  };

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle confirmation
  const handleConfirm = () => {
    if (selectedPlan === 0) {
      // process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? navigate(`/payment/?pid=RAZB9vQMpvfQSd`) : ;
      navigate(`/payment/?pid=RAZMTyeZ0I3liW`);
    }
    else if (selectedPlan === 1) {
      navigate(`/payment/?pid=RAZQ0dgxsb5ka0`);
    }
    else if (selectedPlan === 2) {
      navigate(`/payment/?pid=RAZRg4rI31zjTd`);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>Get Model Credits</h2>
          <button className="close-button" onClick={handleClose}>✕</button>
        </div>
        <p>
        In line with our mission, we offer free credits/week. However, running advanced AI-models is expensive. So, we also offer paid options. <br/><br/>Paid model credits do not expire.<br/>
        </p>

        {/* <div className="billing-toggle">
          <span>One-time</span>
          <label className="switch">
            <input type="checkbox" checked={isYearly} onChange={handleBillingToggle} />
            <span className="slider round"></span>
          </label>
          <span>Subscription</span>
        </div> */}

        <div className="plan-options">
          <button
            className={`plan basic ${selectedPlan === 0 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(0)}
          >
            {/* <h3>{isYearly ? '$3.99' : '$4.99'}</h3>
            <p>{isYearly ? 'Billed Weekly' : 'One Time'}</p> */}
            <h3>$4.99</h3>
            <ul>
              <li>✔ Easiest to Start</li>
              <li>✔ $1.66 per Model Credit</li>
              <li>✔ If you trade 1x/week</li>
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 1 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(1)}
          >
            {/* <h3>{isYearly ? <>$12.99</> : <>$11.99</>}</h3>
            <p>{isYearly ? 'Billed monthly' : 'One-time'}</p> */}
            <h3>$11.99</h3>
            <ul>
              <li>✔ Save 28% (or $4.5) </li>
              <li>✔ $1.20 per Model Credit</li>
              <li>✔ If you trade 3-5x/week</li>
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 2 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(2)}
          >
            {/* <h3>{isYearly ? <>$12.99</> : <>$18.99</>}</h3>
            <p>{isYearly ? 'Billed monthly' : 'One-time'}</p> */}
            <h3>$18.99</h3>
            <ul>
              <li>✔ Save 29% (or $7.5)</li>
              <li>✔ $0.90 per Model Credit</li>
              <li>✔ Best for Daily Traders</li>
            </ul>
          </button>
        </div>
        <div className="popup-footer">
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="confirm-button" onClick={() => handleConfirm()}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePopup;
