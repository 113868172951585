import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import './TrendingPage.css'; // Custom CSS for the Trending Page
import UpgradePopup from './UpgradePopup';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

const TrendingPage = ({ updateActiveTab, handleDisplayData }) => {
  const [data, setData] = useState([]); // State for storing the fetched data
  const [user, setUser] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // State for storing filtered data
  const [loading, setLoading] = useState(true); // State for handling loading state
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [sortOrder, setSortOrder] = useState(''); // State to track sort order ('latest' or 'oldest')
  const [currentPage, setCurrentPage] = useState(1); // State for managing current page
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const itemsPerPage = 8; // Display 10 items per page
  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
        } else {
            setUser(null); 
        }
        });
    return () => unsubscribe();
}, [user]);

  useEffect(() => {
    // Simulate an API call to fetch trending data
    const fetchData = async () => {
      try {
        const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';
        const response = await fetch(`${baseURL}/trending`);
        const result = await response.json();
        setData(result.trending_tickers);
        console.log(result.trending_tickers);
        setFilteredData(result.trending_tickers);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching trending data", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle search term change
  useEffect(() => {
    const searchResults = data.filter((entry) =>
      entry.ticker.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(searchResults);
    setCurrentPage(1); // Reset to page 1 on search
  }, [searchTerm, data]);

  // Handle sorting by latest or oldest
  useEffect(() => {
    if (sortOrder) {
      const sortedData = [...filteredData].sort((a, b) => {
        const dateA = new Date(a.researchDate);
        const dateB = new Date(b.researchDate);
        return sortOrder === 'latest' ? dateB - dateA : dateA - dateB;
      });
      setFilteredData(sortedData);
    }
  }, [sortOrder, filteredData]);

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleViewPrediction = async (prediction_id, index) => {
    const userID = localStorage.getItem('uid');
    if (localStorage.getItem('credits') < process.env.REACT_APP_CREDITS_TO_SUBTRACT_TRENDING && (userID!= data[index].user_id)) {
      setUpgradePopup(true);
    }
    else {
      updateActiveTab('analyze');
      navigate(`/dashboard?pid=${prediction_id}`);
    }
  };

  return (
    <>
    <UpgradePopup 
    isOpen={upgradePopup} 
    handleClose={() => setUpgradePopup(false)} 
  />
    <div className="trending-page-container">
      {/* Minimal Headline */}
      <h3 className="min-headline">Trending</h3>
      
      <p className='trending-disclaimer'> View AI analysis from other users. Costs {process.env.REACT_APP_CREDITS_TO_SUBTRACT_TRENDING} credit per view.</p>

      <div className="trending-container">
        {/* Inside the box: Header, Search, Filter */}
        <div className="header-row">

          <div className="right-side-controls">
            <input
              type="text"
              className="search-bar"
              placeholder="Search by ticker symbol..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Dropdown for sorting with "Sort By" always as a placeholder */}
            <select
              className="sort-dropdown"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="latest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>

        <div className="trending-table-container">
          <table className="trending-table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Ticker</th>
                {/* <th>Time</th> */}
                <th>AI Analysis</th>
                <th>Research Date</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>Loading data...</td>
                </tr>
              ) : currentData.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>No trending data found</td>
                </tr>
              ) : (
                currentData.map((entry, index) => (
                  <tr key={index}>
                    <td> {entry.name.includes('.') && entry.name.split('.').length === 4 
        ? `***.***.${entry.name.split('.')[2]}.${entry.name.split('.')[3]}` 
        : entry.name.split(" ").length > 1 
            ? `${entry.name.split(" ")[0].charAt(0)}${'*'.repeat(entry.name.split(" ")[0].length - 1)} ${entry.name.split(" ")[1].charAt(0)}${'*'.repeat(entry.name.split(" ")[1].length - 1)}`
            : entry.name.length !== 0 
                ? entry.name 
                : 'No name'}</td>
                    <td>{entry.ticker}</td>
                    {/* <td>{entry.time}</td> */}
                    <td><button className="view-prediction" onClick={() => handleViewPrediction(entry.prediction_id,index)}>View</button></td>
                    <td>{entry.research_date}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          <span>Showing {indexOfFirstItem + 1} to {indexOfLastItem < filteredData.length ? indexOfLastItem : filteredData.length} of {filteredData.length} entries</span>
          <div className="pagination-controls">
            <button className="page-btn" onClick={handlePrevPage} disabled={currentPage === 1}>&lt;</button>
            <button className="page-btn active">{currentPage}</button>
            <button className="page-btn" onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>&gt;</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TrendingPage;
